import React, { useState } from 'react';
import styles from './About.module.scss';

const data = [
  {
    title: 'Embark on Quests',
    description: 'Complete randomly generated & hand-made quests: challenging the player, their skill, and their roster of pawns.',
    card: 'embark',
  },
  {
    title: 'Recruit & Train',
    description: 'Assemble a group of mercenaries to do your bidding, leveling them up by completing tasks, and customizing them to your liking',
    card: 'recruit',
  },
  {
    title: 'Socialize & Form Guilds',
    description: 'Band together with fellow adventurers, sharing knowledge of the world, and forming guilds to face the dangers of the world together!',
    card: 'socialize',
  },
  {
    title: 'Explore the World',
    description: 'Sanctum is filled with rich lore, which will be unlocked by simply playing the game. The further you progress, the more secrets of the world reveal themselves.',
    card: 'explore',
  },
  {
    title: 'Prove your Strength',
    description: 'Test your mettle in PvP & PvE combat, battling against hostile monsters or even the mercenaries of another player!',
    card: 'prove',
  },
  {
    title: 'Craft & Equip',
    description: 'Delve into dark magics to spawn the greatest pawns, or simply rely on hammer and steel to outfit your squad with the thickest armor & sharpest blades.',
    card: 'craft',
  },
];

const DataToDisplay = ({ elem }) => {
  const [text, setText] = useState(elem.title);
  return (
    <div
      key={elem.title}
      className={styles[elem.card]}
      onMouseOver={() => setText(elem.description)}
      onMouseLeave={() => setText(elem.title)}
    >
      <div className={styles.dark_bg} />
      <span>{text}</span>
    </div>
  )
}

const About = () => {
  return (
    <div className={styles.wrapper}>
      {data.map((elem) => (
        <DataToDisplay elem={elem} key={elem.title} />
      ))}
    </div>
  );
};

export default About;
