import React from 'react';
import styles from './ClassCard.module.scss';
import warriorIcon from '../../assets/img/Warrior icon Disc.png';
import mageIcon from '../../assets/img/Mage icon Disc.png';
import archerIcon from '../../assets/img/Archer icon Disc.png';
import separator from '../../assets/img/card_separator.png';

const classesData = {
  warrior: {
    text: 'Relying on a combination of brute strength, and discipline - Warriors excel in close-quarters combat. Morality be damned, most lend their sword to the highest bidder, bashing and slashing to riches.',
    icon: warriorIcon,
  },
  mage: {
    text: 'An affinity for arcane  study, and a gifted soul, allow one to manifest all sorts of useful incantations. Mages can be equally as useful on the battlefield as off, deciphering ancient text one minute and raining down fiery destruction the next.',
    icon: mageIcon,
  },
  archer: {
    text: 'Whether poacher or soldier before, now the Archer offers their bow for hire. Their honed instinct can track foes, their ingenuity can lead them into a trap, and their powerful draw can let loose deadly volleys upon opponents.',
    icon: archerIcon,
  }
}

const ClassCard = ({ type }) => (
  <div className={`${styles.wrapper} ${styles[type]}`}>
    <div className={styles.logo}>
      <img src={classesData[type].icon} alt="" />
    </div>
    <div className={styles.title}>
      {type}
    </div>
    <div className={styles.separator}>
      <img src={separator} alt="" />
    </div>
    <div className={styles.description}>
      {classesData[type].text}
    </div>
  </div>
);

export default ClassCard;
