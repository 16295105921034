import React from 'react';
import styles from './Chapter.module.scss';

const Chapter = ({ title }) => (
  <div className={styles.wrapper}>
    {title}
  </div>
);

export default Chapter;
