import React, {useRef} from 'react';
import { useState } from 'react';
import styles from './App.module.scss';
import portraits from './assets/img/Portrait collage.png';
import archer from './assets/img/archer-level1-idle.gif';
import mage from './assets/img/mage-level1-idle.gif';
import warrior from './assets/img/warrior-level1-idle.gif';
import buttonImg from './assets/img/button.png';
import buttonPressed from './assets/img/button_pressed.png';
import decoration from './assets/img/decoration.png';
import roadmapMap from './assets/img/roadmap/roadmap_illustration.png';
import roadmapDecoration from './assets/img/roadmap/roadmap_decoration.png';
import about from './assets/img/About.png';
import roadMap from './assets/img/Roadmap.png';
import market from './assets/img/Market.png';
import docs from './assets/img/Docs.png';
import docsHover from './assets/img/Docs hover.png'
import docsPressed from './assets/img/Docs pressed.png'
import demo from './assets/img/Litepaper.png';
import demoHover from './assets/img/Litepaper hover.png'
import demoPressed from './assets/img/Litepaper pressed.png'
import media from './assets/img/Medium.png';
import mediaHover from './assets/img/Medium hover.png'
import mediaPressed from './assets/img/Medium pressed.png'
import discord from './assets/img/Discord.png';
import discordHover from './assets/img/Discord hover.png'
import discordPressed from './assets/img/Discord pressed.png'
import twitter from './assets/img/Twitter.png';
import twitterHover from './assets/img/Twitter hover.png'
import twitterPressed from './assets/img/Twitter pressed.png'
import aboutHover from './assets/img/About hover.png';
import aboutActive from './assets/img/About pressed.png';
import roadMapHover from './assets/img/Roadmap hover.png';
import roadMapActive from './assets/img/Roadmap pressed.png';
import marketHover from './assets/img/Market hover.png';
import marketPressed from './assets/img/Market pressed.png';
import sanctumMain from './assets/img/sanctum.png';
import Chapter from './components/Chapter/Chapter';
import Chain from './components/Chain/Chain';
import ClassCard from './components/ClassCard/ClassCard';
import About from './components/About/About';
import TeamCard from "./components/TeamCard";
import Tooltip from './components/Tooltip';

const App = () => {
  const [clicked, setClicked] = useState(false);
  const [aboutButton, setAboutButton] = useState('');
  const [roadMapButton, setRoadMapButton] = useState('');
  const [marketButton, setMarketButton] = useState('');
  const [docsButton, setDocsButton] = useState('');
  const [demoButton, setDemoButton] = useState('');
  const [mediaButton, setMediaButton] = useState('');
  const [twitterButton, setTwitterButton] = useState('')
  const [discordButton, setDiscordButton] = useState('')

  const roadToMap = useRef();
  const aboutTo = useRef();

  const handleMapClick = () => {
    roadToMap.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleAboutClick = () => {
    aboutTo.current.scrollIntoView({ behavior: 'smooth' })
  }

  const aboutStatus = {
    hover: aboutHover,
    pressed: aboutActive,
  };

  const roadMapStatus = {
    hover: roadMapHover,
    pressed: roadMapActive,
  };

  const marketStatus = {
    hover: marketHover,
    pressed: marketPressed,
  };

  const docsStatus = {
    hover: docsHover,
    pressed: docsPressed,
  };

  const demoStatus = {
    hover: demoHover,
    pressed: demoPressed,
  };

  const mediaStatus = {
    hover: mediaHover,
    pressed: mediaPressed,
  };

  const discordStatus = {
    hover: discordHover,
    pressed: discordPressed,
  };

  const twitterStatus = {
    hover: twitterHover,
    pressed: twitterPressed,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.wrapper_bar}>
            <img
              src={aboutButton ? aboutStatus[aboutButton] : about}
              alt=""
              onClick={() => handleAboutClick()}
              onMouseEnter={() => setAboutButton('hover')}
              onMouseDown={() => setAboutButton('pressed')}
              onMouseUp={() => setAboutButton('hover')}
              onMouseLeave={() => setAboutButton('')}
              className={styles.aboutBtn}
            />
            <img
              src={roadMapButton ? roadMapStatus[roadMapButton] : roadMap}
              alt=""
              onClick={() => handleMapClick()}
              onMouseEnter={() => setRoadMapButton('hover')}
              onMouseDown={() => setRoadMapButton('pressed')}
              onMouseUp={() => setRoadMapButton('hover')}
              onMouseLeave={() => setRoadMapButton('')}
              className={styles.roadmapBtn}
            />
            <img
              src={marketButton ? marketStatus[marketButton] : market}
              alt=""
              onMouseEnter={() => setMarketButton('hover')}
              onMouseDown={() => setMarketButton('pressed')}
              onMouseUp={() => setMarketButton('hover')}
              onMouseLeave={() => setMarketButton('')}
              className={styles.marketBtn}
              onClick={()=> window.open("https://app.sanctum.community/", "_blank")}
            />
            <img
              src={docsButton ? docsStatus[docsButton] : docs}
              alt=""
              className={styles.docsBtn}
              onMouseEnter={() => setDocsButton('hover')}
              onMouseDown={() => setDocsButton('pressed')}
              onMouseUp={() => setDocsButton('hover')}
              onMouseLeave={() => setDocsButton('')}
              onClick={()=> window.open("https://sanctum-1.gitbook.io/sanctum/", "_blank")}
            />
          <img
              src={demoButton ? demoStatus[demoButton] : demo}
              alt=""
              className={styles.demoBtn}
              onMouseEnter={() => setDemoButton('hover')}
              onMouseDown={() => setDemoButton('pressed')}
              onMouseUp={() => setDemoButton('hover')}
              onMouseLeave={() => setDemoButton('')}
              onClick={()=> window.open(`/sanctum_litepaper_v3.pdf`, "_blank")}
          />
          <img
              src={mediaButton ? mediaStatus[mediaButton] : media}
              alt=""
              onMouseEnter={() => setMediaButton('hover')}
              onMouseDown={() => setMediaButton('pressed')}
              onMouseUp={() => setMediaButton('hover')}
              onMouseLeave={() => setMediaButton('')}
              onClick={()=> window.open("https://medium.com/@crypto_sanctum", "_blank")}
              className={styles.mediaBtn}
          />
          <img
              src={discordButton ? discordStatus[discordButton] : discord}
              alt=""
              onMouseEnter={() => setDiscordButton('hover')}
              onMouseDown={() => setDiscordButton('pressed')}
              onMouseUp={() => setDiscordButton('hover')}
              onMouseLeave={() => setDiscordButton('')}
              onClick={()=> window.open("https://discord.com/invite/VJEFWHPbEA", "_blank")}
              className={styles.discordBtn}
          />
          <img
              src={twitterButton ? twitterStatus[twitterButton] : twitter}
              alt=""
              onMouseEnter={() => setTwitterButton('hover')}
              onMouseDown={() => setTwitterButton('pressed')}
              onMouseUp={() => setTwitterButton('hover')}
              onMouseLeave={() => setTwitterButton('')}
              onClick={()=> window.open("https://twitter.com/crypto_sanctum", "_blank")}
              className={styles.twitterBtn}
          />
        </div>
      </div>
      <div className={styles.firstSection_container}>
        <div className={styles.firstSection_wrapper}>
          <img src={sanctumMain} alt="logo" />
        </div>
      </div>
      <div className={styles.page_break} />
      <Chapter title="Write history" />
      <div className={styles.history_wrapper}>
        <div className={styles.history}>
          <div className={styles.history_portraits}>
            <img src={portraits} alt="" />
          </div>
          <div className={styles.history_description}>
            <Chain top />
            <div className={styles.history_description_text}>
              Sanctum brings the best of gaming and thoughtfully combines it with the most
              modern play-to-earn systems on Arbitrum. As a guild master, you will utilize your
              pawns (NFTs) to complete quests, and battle in PVE or PVP-yielding real rewards.
            </div>
            <Chain />
          </div>
          <div className={styles.classes}>
            <div>
              <img src={warrior} alt="" />
            </div>
            <div>
              <img src={mage} alt="" />
            </div>
            <div>
              <img src={archer} alt="" />
            </div>
          </div>
          <div className={styles.classes_cards}>
            <ClassCard type='warrior' />
            <ClassCard type='mage' />
            <ClassCard type='archer' />
          </div>
          <div className={styles.start}>
            <Chain top />
            <div className={styles.start_title}>
              Start your adventure today!
            </div>
            <button
              type="button"
              onMouseDown={() => setClicked(true)}
              onMouseUp={() => setClicked(false)}
            >
              <img
                src={clicked ? buttonPressed : buttonImg}
                alt="Play now" className={styles.passButton}
                width="298px"
                height="74px"
              />
              <div
                role="presentation"
                className={clicked ? styles.button_text : ''}
                onClick={()=> window.open("https://app.sanctum.community/", "_blank")}
              >
                Play now
              </div>
            </button>
            <Chain />
          </div>
        </div>
      </div>
      <div className={styles.page_break} />
      <Chapter title="About" />
      <div className={styles.about_wrapper} ref={aboutTo}>
        <div className={styles.about}>
          <Chain top />
          <About />
          <Chain />
          <div className={styles.about_decoration}>
            <img src={decoration} alt="" />
          </div>
        </div>
      </div>
      <div className={styles.page_break} />
      <Chapter title="Roadmap" />
      <div className={styles.roadmap} ref={roadToMap}>
        <Chain top />
        <div className={styles.roadmap_map} >
          <img src={roadmapMap} alt="" />
          <div className={styles.roadmap_design}>
            <div className={styles.roadmap_design_title}>
              Preliminary Design
            </div>
            <div
              className={styles.roadmap_design_img}
              data-tip
              data-for='PreliminaryDesign'
            />
          </div>
          <Tooltip
            id="PreliminaryDesign"
            title="Preliminary Design"
            description="Write the lore behind Sanctum, create the basic gameplay structures, and plan out the economy."
          />
          <div className={styles.roadmap_presale}>
            <div>Pre-sale</div>
            <div
              className={styles.roadmap_presale_img}
              data-tip
              data-for='Presale'
            />
          </div>
          <Tooltip
            id="Presale"
            title="Pre-sale"
            description="Raise funds for development and liquidity through an NFT sale of our initial supply of pawns."
          />
          <div className={styles.roadmap_demo}>
            <div>
              Demo
              <br/>
              &
              <br/>
              Updates
            </div>
            <div
              className={styles.roadmap_demo_img}
              data-tip
              data-for='Demo'
            />
          </div>
          <Tooltip
            id="Demo"
            title="Demo & Incremental Updates"
            description="Release the initial demo of combat onto the web-app, following by other modules and incremental improvements to existing ones."
          />
          <div className={styles.roadmap_airdrop}>
            <div>
              Token listing
              <br/>
              &
              <br/>
              airdrop
            </div>
            <div
              className={styles.roadmap_airdrop_img}
              data-tip
              data-for='Airdrop'
            />
          </div>
          <Tooltip
            id="Airdrop"
            title="Token Listing & Airdrop"
            description="Listing of our $SANCTA token onto a DEX, and airdropping of our token across the pre-sale pawns."
          />
          <div className={styles.roadmap_release}>
            <div>
              Initial Release
            </div>
            <div
              className={styles.roadmap_release_img}
              data-tip
              data-for='Release'
            />
          </div>
          <Tooltip
            id="Release"
            title="Initial Release"
            description="The launch of 1.0 version of the game, including the initial idle & combat modules, connected with the economic system behind the game."
          />
          <div className={styles.roadmap_upgrade}>
            <div>??????????</div>
            <div
              className={styles.roadmap_upgrade_img}
              data-tip
              data-for='Upgrade'
            />
          </div>
          <Tooltip
            id="Upgrade"
            title="????????"
            description="The future is bright and full of features.."
          />
        </div>
        <Chain />
        <div className={styles.roadmap_decoration}>
          <img src={roadmapDecoration} alt="" />
        </div>
      </div>
      <div className={styles.page_break} />
      <Chapter title="Team" />
        <div className={styles.team}>
          <div className={styles.wrapper_cards}>
              <TeamCard info={{person: 'mission', category: 'Founder'}}/>
              <TeamCard info={{person: 'smeshuljak', category: 'Community Manager'}}/>
              <TeamCard info={{person: 'viktor', category: 'Founder'}}/>
              <TeamCard info={{person: 'windscr3wer', category: 'Artist'}} silver />
              <TeamCard info={{person: 'M3RL1N', category: 'Advisor'}} silver />
              <TeamCard info={{person: 'code wizards', category: 'Various Developers'}} silver />
          </div>
        </div>
      <div className={styles.footer}>
        <span>Made by Selective</span>
      </div>
    </div>
  );
}

export default App;
