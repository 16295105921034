import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';
import separator from '../../assets/img/tooltip/separator.png';

const Tooltip = ({ title, description, id }) => (
  <ReactTooltip
    id={id}
    place="bottom"
    backgroundColor="none"
    arrowColor="transparent"
    effect="solid"
  >
    <div className={styles.wrapper}>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.separator}>
        <img src={separator} alt="" />
      </div>
      <div className={styles.description}>
        {description}
      </div>
    </div>
  </ReactTooltip>
);

export default Tooltip;
