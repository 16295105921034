import React from "react";
import Mission from '../../assets/img/Mission.png';
import Piotr from '../../assets/img/Piotr.png';
import Viktor from '../../assets/img/Viktor.png';
import Qama from '../../assets/img/QAMA.png';
import Harm from '../../assets/img/Hamzalopode.png';
import Wind from '../../assets/img/Windscr3wer.png';
import styles from "./TeamCard.module.scss";

export const TeamCard = ({ info, silver }) => {

  const team = {
    mission: Mission,
    smeshuljak: Piotr,
    viktor: Viktor,
    M3RL1N: Harm,
    windscr3wer: Wind,
    'code wizards': Qama,
  }

  return (
      <div className={styles.wrapper}>
        <img src={team[info.person]} alt="" />
        <div className={`${styles.content} ${silver ? styles.silver : ''}`}>
          <span className={styles.person}>{info.person}</span>
          <span>{info.category}</span>
        </div>
      </div>
  )
}

export default TeamCard;
